@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer utilities {
  .preserve-3d {
    transform-style: preserve-3d;
  }
  
  .perspective-1000 {
    perspective: 1000px;
  }
  
  .backface-hidden {
    backface-visibility: hidden;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Keyframes and Animations */
@keyframes simpleFlip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Custom Utilities */
@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }
  
  .preserve-3d {
    transform-style: preserve-3d;
  }
  
  .backface-hidden {
    backface-visibility: hidden;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

/* Optional: More complex flip animation if needed */
@keyframes complexFlip {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  25% {
    transform: rotateX(180deg) rotateY(0);
  }
  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: rotateX(0) rotateY(180deg);
  }
  100% {
    transform: rotateX(0) rotateY(360deg);
  }
}

/* Additional utility classes for transitions */
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-700 {
  transition-duration: 700ms;
}


@keyframes pulse-intensity {
  0% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(0.95);
    filter: brightness(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1);
  }
}

/* Add a glow effect behind the logo */
.logo-glow {
  position: absolute;
  inset: -10px;
  background: radial-gradient(circle, rgba(34, 211, 238, 0.2) 0%, transparent 70%);
  filter: blur(8px);
  opacity: 0.8;
  z-index: 0;
}

/* Optional: for a more dramatic effect */
@keyframes pulse-intensity-dramatic {
  0% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1) drop-shadow(0 0 5px rgba(34, 211, 238, 0.3));
  }
  50% {
    opacity: 0.8;
    transform: scale(0.92);
    filter: brightness(1.3) drop-shadow(0 0 15px rgba(34, 211, 238, 0.5));
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1) drop-shadow(0 0 5px rgba(34, 211, 238, 0.3));
  }
}